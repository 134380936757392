<template>
  <!-- Start Service Area  -->
  <v-row class="service-one-wrapper">
    <!-- Start Single Service  -->
    <v-col
      lg="6"
      md="12"
      sm="12"
      cols="12"
      v-for="(service, i) in marineItem"
      :key="i"
    >
      <div class="single-service service__style--siber d-flex align-center" style="height: 210px !important;">
        <!-- <router-link to="/service-details"> -->
          <div class="service d-flex flex-row">
            <div class="icon" v-html="iconSvg(service.icon)"></div>
            <div class="content ml-5">
              <!-- <h3 class="heading-title">{{ service.title }}</h3> -->
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        <!-- </router-link> -->
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
  <!-- Start Service Area  -->
</template>

<script>
import feather from "feather-icons";

  export default {
    components: {
    },
      data() {
        return {
          marineItem: [
            {
              icon: "command",
              // title: "ADS-B Receiver",
              desc:"Create data limit per debtor that a limit aggregation per loan type in cash loan, non cash loan, treasury line, and pre-approve that already integrated with the master data portfolio in account id and also facility id"
            },
            {
              icon: "percent",
              // title: "Real-time Data Storing, Integration, Analytics & Visualization",
              desc:"Create data limit that counting result with particular description like Legal Lending Limit/BMPK, Inhouse Limit, Master Limit, and value chain limit where in the nominative per CIF, Account, facility, and product/product."
            },
            {
              icon: "shopping-cart",
              // title: "Satellite Based Data",
              desc:"Create data mart that contains master data portfolio by combining total of group usaha (parent and group usaha member) and non group usaha for the CIF, Account, Facility, Product, Debet Balance, Credit Quality, Period, Credit Status from Credit Type (CL, NCL, TL)."
            },
            {
              icon: "file-text",
              // title: "Potensi Use-Case (Manifest & FLIFO)",
              desc:"Create data mart that contains descriptive report that ready to integrate with business sector business base on regulator, internal, or group usaha ecosystem, also it is available for country limit, industry limit, currency limit, and attribute tagging for global line, interchangeable, and switchable."

            },
            {
              icon: "database",
              // title: "Satellite Based Data",
              desc:"Create data mart that contains customer retail for the CIF, Account, Facility, Product, Credit Balance, Credit Quality, Period, Credit Status from credit type (CL, NCL, and TL)."
            },
            
            {
              icon: "file-plus",
              // title: "Satellite Based Data",
              desc:"Create retail data mart that contains descriptive report that already integrate with the business sector dimension base on regulator, internal and business ecosystem, also that is available for the dimension for the country limit, currency limit, and tagging for the switchable if necessary."
            },
          ],
        };
      },
      methods: {
        iconSvg(icon) {
          return feather.icons[icon].toSvg();
        },
      },
    };
</script>
<style scoped>
 .centang {
  vertical-align: top;
  min-width: 17px;
  padding-top: 5px;
  margin-bottom: 10px !important;
 }
 .feather {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}
</style>
