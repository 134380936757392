<template>
  <div>
    <!-- Start Header Area -->
    <!-- <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile"/> -->
    <!-- <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none"/> -->
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <!-- <div
      class="slide slide-style-2Limit slider-video-bg d-flex align-center justify-center overlay-fix"
      data-black-overlay="1"
    >
      <v-container>
        <v-row>
            <v-col cols="12">
                <h2 class="heading-title1 text-left font-weight-bold" style="font-size: 60px !important; line-height: 90px !important; letter-spacing: 2px !important;">
                    Datapipe
                </h2>
            </v-col>
            <v-col cols="12" class="inner text-left d-flex flex-column align-start pt-0">
                <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light" style="line-height: 40px !important;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</h5>
            </v-col>
        </v-row>
        <div>

        </div>
      </v-container>

      <div class="photo-background">
        <span>
          <img src="../assets/images/bg/business-5475664.png" alt="" style="background-repeat: no-repeat; background-size: cover; background-position: center center;"/>
        </span>
      </div>
    </div> -->
    <!-- End Slider Area -->

    <!-- Start Content -->
    <div class="rn-contact-area rn-section-gap bg_color--1" style="padding-top:0px; padding-bottom:0px">
      <div class="contact-form--1">
        <v-container>
          <div class="section rn-blog-area rn-section-gap" id="blog">
            <div class="section-title section-title--2 text-center mb--20">
              <h4 class="heading-title">Unlock your SAP data</h4>
              <h3 class="heading-title mb-5">
                Turn SAP Data into Critical Business Insights
              </h3>
              <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
              <p style="text-align: center; font-size:24px !important" class="my-8">
                Unlocks SAP Data & <b style="font-weight: 700;">Accelerates</b> Data Warehouse Automation.
                Agility, Do More with your SAP Data & Lower TCO!
              </p>
              <p>
              </p>
            </div>
            <!-- <ServiceLimitManagementSystem/> -->
            <v-row class="service-one-wrapper">
              <!-- Start Single Service  -->
              <v-col lg="6" md="6" sm="12" cols="12" v-for="(service, i) in marineItem" :key="i">
                <div class="single-service service__style--siber d-flex align-center" style="height: 100px !important;">
                  <!-- <router-link to="/service-details"> -->
                  <div class="service d-flex flex-row">
                    <div class="icon" v-html="iconSvg('check')"></div>
                    <div class="content ml-5">
                      <!-- <h3 class="heading-title">{{ service.title }}</h3> -->
                      <p v-html="service.desc">
                      </p>
                    </div>
                  </div>
                  <!-- </router-link> -->
                </div>
              </v-col>
              <!-- End Single Service  -->
            </v-row>
          </div>
        </v-container>
      </div>
    </div>
    <!-- End Content -->

    <!-- Start Content -->
    <div class="rn-contact-area rn-section-gap bg_color--5"
      style="padding-top:0px !important; padding-bottom:0px !important">
      <div class="contact-form--1">
        <v-container>
          <div class="section rn-blog-area rn-section-gap" id="blog">
            <div class="section-title section-title--2 text-center mb--20">
              <h4 class="heading-title">Providing Active Intelligence for Real-time Decisions</h4>
              <h3 class="heading-title mb-5">
                Actionable Insights & Timely Decisions!
              </h3>
              <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
              <!-- <p style="text-align: center; font-size:24px !important" class="my-8">
                Unlocks SAP Data & <b style="font-weight: 700;">Accelerates</b> Data Warehouse Automation.
                Agility, Do More with your SAP Data & Lower TCO!
              </p> -->
              <p>
              </p>
            </div>
            <!-- <ServiceLimitManagementSystem/> -->
            <v-row>
              <!-- Start Single Service  -->

              <v-col lg="6" md="6" sm="6" cols="12" class="bg_color--5 mt-10 mb-10">
                <div
                  style="height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: left;">
                  <div v-for="(service, i) in listItem2" :key="i" class="d-flex justify-left"
                    style="height: fit-content;">
                    <!-- <router-link to="/service-details"> -->
                    <div class="service d-flex flex-row justify-left mb-5">
                      <v-icon color="#dd8732" class="mr-10">mdi-check</v-icon>
                      <p v-html="service.desc">

                      </p>
                    </div>
                    <!-- </router-link> -->
                  </div>
                </div>
              </v-col>
              <!-- <v-col lg="6" md="6" sm="6" cols="12">
                <div v-for="(service, i) in listItem2" :key="i"
                  class="single-service service__style--siber d-flex align-left">
                  <div class="service d-flex flex-row">
                    <div class="icon" v-html="iconSvg('check')">

                    </div>
                    <div class="content ml-5">
                      <p v-html="service.desc">

                      </p>
                    </div>
                  </div>
                </div>
              </v-col> -->
              <v-col style="display: flex; justify-content: center; align-items: center; padding: 20px;">
                <img src="../assets/images/contents/sap/QLIK ppt for website.png" style="width: 100% !important;" />
              </v-col>
              <!-- End Single Service  -->
            </v-row>

            <v-row class="rn-contact-area rn-section-gap bg_color--5">
              <!-- Start Single Service  -->
              <v-col style="display: flex; justify-content: center; align-items: center; padding: 20px;">
                <img src="../assets/images/contents/sap/QLIK ppt for website 1.png" style="width: 100% !important;" />
              </v-col>
              <v-col lg="6" md="6" sm="6" cols="12" class="bg_color--5">
                <div
                  style="height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: left;">
                  <div v-for="(service, i) in listItem3" :key="i" class="d-flex justify-left"
                    style="height: fit-content;">
                    <!-- <router-link to="/service-details"> -->
                    <div class="service d-flex flex-row justify-left mb-5">
                      <v-icon color="#dd8732" class="mr-10">mdi-check</v-icon>
                      <p v-html="service.desc">

                      </p>
                    </div>
                    <!-- </router-link> -->
                  </div>
                </div>
              </v-col>
              <!-- End Single Service  -->
            </v-row>
          </div>
        </v-container>
      </div>
    </div>
    <!-- <div class="rn-contact-area rn-section-gap bg_color--5"
        style="padding-top:0px !important; padding-bottom:0px !important">
      </div> -->
    <!-- End Content -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
import HeaderOnePageTwo from "../components/header/HeaderOnePageTwoSiber.vue";
import HeaderOnePageTwoMobile from "../components/header/HeaderOnePageTwoSiberMobile.vue";
import Contact from "../components/contact/Contact";
import ServiceLimitManagementSystem from "../components/service/ServiceLimitManagementSystem.vue";
import Footer from "../components/footer/Footer";
import feather from "feather-icons";
import sap1 from "../assets/images/contents/sap/QLIK ppt for website.png"
export default {
  components: {
    HeaderOnePageTwo,
    HeaderOnePageTwoMobile,
    Contact,
    ServiceLimitManagementSystem,
    Footer,
  },
  data() {
    return {
      listItem2: [
        {
          desc: 'Near <b>real-time</> order-to-cash analytics via SAP data integration'
        },
        {
          desc: '<b>Pre-built</b> dashboard of top KPIs, smart visualizations, master library items, and calculations—100s of hours in time savings'
        },
        {
          desc: '<b>Free-form exploration and drill-down</b> into transactional level data and root-cause analysis;e.g., to analyze days sales outstanding (DSO), drill into # days order-to-ship, # days ship-to-bill, and # days bill-to-cash to determine where exactly are the bottlenecks'
        },
      ],
      listItem3: [
        {
          desc: 'Order-to-cash business logic and vocabulary that powers and enhances <b>natural language queries & conversational analytics</b>'
        },
        {
          desc: '<b>Intelligent alerting</b> for real-time action'
        },
        {
          desc: 'AI-enabled <b>Insight Advisor</b> to accelerate insights generation'
        },
        {
          desc: '<b>Integrated catalog</b> for data self-service'
        },
        {
          desc: '<b>Server-side authoring</b> to avoid desktop client downloads and overheads'
        },
        {
          desc: 'Native <b>mobile access/apps</b> with responsible design and best-in-class EMM'
        },
        {
          desc: '<b>Flexible deployment options:</b> SaaS, on-prem, multi-cloud'
        },
      ],
      marineItem: [
        {
          icon: "command",
          // title: "ADS-B Receiver",
          desc: "Near <b>real-time</b> ingestion processes using SAP Extractors and CDC"
        },
        {
          icon: "percent",
          // title: "Real-time Data Storing, Integration, Analytics & Visualization",
          desc: "<b>Efficiently integrate</b> SAP with non-SAP data sources & move to cloud"
        },
        {
          icon: "shopping-cart",
          // title: "Satellite Based Data",
          desc: "<b>Pre-built</b> Order-to-Cash <b>data warehouse automation</b>, which can be extended or customized for industry-specific requirements  "
        },
        {
          icon: "file-text",
          // title: "Potensi Use-Case (Manifest & FLIFO)",
          desc: "1000's of hours in data integration <b>savings</b> and automation"

        },
        {
          icon: "database",
          // title: "Satellite Based Data",
          desc: "<b>Blueprint</b> with business logic and order-to-cash analytics data marts"
        },
        {
          icon: "file-plus",
          // title: "Satellite Based Data",
          desc: "Decoded proprietary SAP source structures into <b>analytics-ready data marts</b> that can be understood by business analysts"
        },
        {
          icon: "file-plus",
          // title: "Satellite Based Data",
          desc: "Scale storage and compute with <b>your choice of cloud platform</b>"
        },
        {
          icon: "file-plus",
          // title: "Satellite Based Data",
          desc: "<b>Extendable</b>, e.g. for real-time data streaming with kafka or predictive analytics with AI/ML integrations"
        },
      ],
      features: [
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/monitor.svg"),
          titleFeatures: "Map Based Flight Monitoring",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/info.svg"),
          titleFeatures: "Flight and Airport Information Panel",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/filter.svg"),
          titleFeatures: "Flight Search & Filtering",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/database.svg"),
          titleFeatures: "Real-time Data Source Processing",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/play.svg"),
          titleFeatures: "Playback Flights",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/bell.svg"),
          titleFeatures: "Custom Alert & Notification",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/pie-chart.svg"),
          titleFeatures: "Analytics & Reporting",
        },
      ]
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  }
};
</script>
<style scoped>
.heading-title1 {
  color: #dd8732 !important;
  line-height: 60px;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-title2 {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle2 {
  color: #dd8732 !important;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-caption {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
  font-size: 16px !important;
  line-height: 25px;
  font-weight: 500;
}

.heading-caption2 {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
  font-size: 16px !important;
  line-height: 25px;
  font-weight: 500;
}

.item-subtitle {
  color: #E18122 !important;
  font-family: 'Urbanist', sans-serif !important;
}</style>