<template>
  <div>
    <!-- Start Header Area -->
    <!-- <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile"/> -->
    <!-- <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none"/> -->
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <!-- <div
      class="slide slide-style-2Limit slider-video-bg d-flex align-center justify-center overlay-fix"
      data-black-overlay="1"
    >
      <v-container>
        <v-row>
            <v-col cols="12">
                <h2 class="heading-title1 text-left font-weight-bold" style="font-size: 60px !important; line-height: 90px !important; letter-spacing: 2px !important;">
                    Personal Financial Management
                </h2>
            </v-col>
            <v-col cols="12" class="inner text-left d-flex flex-column align-start pt-0">
                <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light" style="line-height: 40px !important;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</h5>
            </v-col>
        </v-row>
        <div>

        </div>
      </v-container>

      <div class="photo-background">
        <span>
          <img src="../assets/images/bg/business-5475664.png" alt="" style="background-repeat: no-repeat; background-size: cover; background-position: center center;"/>
        </span>
      </div>
    </div> -->
    <!-- End Slider Area -->

    <!-- Start Content -->
    <div class="rn-contact-area rn-section-gap bg_color--1" style="padding-top:0px; padding-bottom:0px">
      <div class="contact-form--1">
        <v-container>
          <div class="section rn-blog-area rn-section-gap" id="blog">
            <div class="section-title section-title--2 text-center mb--20">
              <h4 class="heading-title">Personal Financial Management (PFM)</h4>
              <h3 class="heading-title mb-5">
                Building blocks of PFM
              </h3>
              <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
              <p style="text-align: center;" class="my-8">Empower Your Finances with PFM: Take Control of Your Financial
                Future!</p>
            </div>

            <v-row>
              <!-- Start Single Service  -->

              <v-col lg="6" md="6" sm="12" cols="12" class="d-flex justify-center align-center">
                <div style="width: 100%;">

                  <div v-for="(service, i) in buildingBlocksItems" :key="i" class="d-flex justify-left ma-10">
                    <!-- <router-link to="/service-details"> -->

                    <div class="triangle">
                      <v-btn icon class="icon ml-5" disabled
                        style="background-color: #dd8732; width: 60px; height: 60px;">
                        <v-icon x-large style="color: black !important;">{{ service.iconMDI }}</v-icon>
                      </v-btn>
                    </div>
                    <div class="service d-flex flex-row justify-left align-center"
                      style="background-color: rgb(221, 218, 218); width: 100%; height: 100px;" id="content-block-pfm">
                      <!-- <div class="icon d-flex flex-row justify-center align-center" icon style="background-color: #dd8732;" v-html="iconSvg(service.icon)"></div> -->

                      <!-- <v-icon class="icon ml-5" x-large color="#dd8732">{{ service.iconMDI }}</v-icon> -->
                      <strong v-html="service.title" class="ml-5" style="font-size: 24px; width: 30%;">

                      </strong>
                      <div
                        style="width: 100%; text-align: center; font-size: 20px; word-wrap:break-word; white-space:normal;">
                        <strong>{{ service.item1 }}</strong>
                        <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 2px  solid #dd8732">
                        <strong>{{ service.item2 }}</strong>
                        <!-- <hr color="#dd8732" width="50px;" style="margin: auto; border-top: 1px  solid #dd8732"> -->
                      </div>
                    </div>
                    <!-- </router-link> -->
                  </div>
                </div>
              </v-col>

              <v-col lg="6" md="6" sm="12" cols="12" class="d-flex flex-start">
                <div class="section-title text-left mt--10 mt_md--5 mt_mobile--5 mb_mobile--10">
                  <h2 class="heading-title mb-5" style="font-size: 40px !important;">
                    Banks are Investing in PFM
                    <br />
                    to Differentiate
                  </h2>
                  <p class="mb-5 whoweare-ket d-flex justify-center align-center">
                    <v-icon large color="#dd8732">
                      mdi-circle-small
                    </v-icon>
                    Customer Retention: 71% of Gen Z believe banks should help them achieve personal goals and aspirations
                  </p>
                  <!-- <p class="ml-3 whoweare-ket">
                Experience, passionate and dynamic professionals with regular refreshed necessary knowledge and skill set. We combine logic, creativity and curiosity to build, solve, and create.
              </p> -->
                  <p class="mb-5 whoweare-ket d-flex justify-center align-center">
                    <v-icon large color="#dd8732">
                      mdi-circle-small
                    </v-icon>
                    Customer Lifetime Value: On average, bank customers who make use of PFM tools are 18% wealthier than
                    those who don’t
                  </p>
                  <!-- <div class="service-btn">
                <a class="btn-transparent rn-btn-dark" href="/service"
                  ><span class="text">Data streaming is our signature</span></a 
                >
              </div> -->
                </div>
              </v-col>
              <!-- <v-col lg="6" md="6" sm="6" cols="12">
                <div v-for="(service, i) in listItem2" :key="i"
                  class="single-service service__style--siber d-flex align-left">
                  <div class="service d-flex flex-row">
                    <div class="icon" v-html="iconSvg('check')">

                    </div>
                    <div class="content ml-5">
                      <p v-html="service.desc">

                      </p>
                    </div>
                  </div>
                </div>
              </v-col> -->
              <v-col style="display: flex; justify-content: center; align-items: center;">

              </v-col>
              <!-- End Single Service  -->
            </v-row>
          </div>
        </v-container>
      </div>
    </div>
    <!-- End Content -->

    <!-- Start Content -->
    <div style="padding: 0px 0px 120px 0px;" class=" bg_color--5">
      <div class="rn-team-area rn-section-gapsiber background-foto1">
        <v-container class="fade-in" style="margin-top: 20px; margin-bottom: 20px">
          <div class="section-title section-title--2siber mb-10">
            <h2 class="exist1-title" style="text-align: center !important;">Building blocks of PFM. How Sibernetik <br />
              can help?
            </h2>
          </div>
          <v-row class="service-one-wrapper">
            <v-col cols="4" v-for="(item, i) in features" :key="i">
              <div class="d-flex flex-column kolom-fitur pa-2">
                <!-- <img
                  style="width: 60px !important; filter: brightness(0) invert(1); margin-bottom: 20px; align-items: center;"
                  :src="item.iconFeatures" /> -->
                <v-icon style="font-size: 60px !important">{{ item.iconFeatures }}</v-icon>
                <span style="text-align: center; font-weight: 300; color: #ffff; font-size: 24px;">
                  {{ item.titleFeatures }}
                </span>
                <span style="text-align: center; font-weight: 100; color: #ffff; font-size: 20px;">
                  {{ item.descriptionFeatures }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-10">
            <v-col cols="3" v-for="(item, i) in taglines" :key="i">
              <div class="d-flex flex-column kolom-fitur pa-2">
                <span style="text-align: center; font-weight: 100; color: #ffff; font-size: 20px;">
                  {{ item.title }}
                </span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <!-- End Content -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
import feather from "feather-icons";
import HeaderOnePageTwo from "../components/header/HeaderOnePageTwoSiber.vue";
import HeaderOnePageTwoMobile from "../components/header/HeaderOnePageTwoSiberMobile.vue";
import Contact from "../components/contact/Contact";
import ServiceLimitManagementSystem from "../components/service/ServiceLimitManagementSystem.vue";
import Footer from "../components/footer/Footer";

export default {
  components: {
    HeaderOnePageTwo,
    HeaderOnePageTwoMobile,
    Contact,
    ServiceLimitManagementSystem,
    Footer,
  },
  data() {
    return {
      taglines: [
        {
          title: "REALTIME INQUIRY BALANCE"
        },
        {
          title: "REALTIME INQUIRY CUSTOMER PROFILE"
        },
        {
          title: "REALTIME INQUIRY CUSTOMER TRANSACTION"
        },
        {
          title: "REALTIME INQUIRY ACCOUNT STATEMENT"
        }
      ],
      buildingBlocksItems: [
        {
          title: 'TRACK',
          item1: 'Spend Analytics',
          item2: 'Transaction Search',
          icon: 'activity',
          iconMDI: 'mdi-road-variant',
        },
        {
          title: 'MANAGE',
          item1: 'Spending Budgets',
          item2: 'Payment Controls',
          icon: 'tool',
          iconMDI: 'mdi-table-cog',
        },
        {
          title: 'PLAN',
          item1: 'Cashflow Alerts',
          item2: 'Saving Goals',
          icon: 'edit',
          iconMDI: 'mdi-notebook',
        },
        {
          title: 'GROW',
          item1: 'Personal Investment Advise',
          item2: 'Access to Financial Planner',
          icon: 'trending-up',
          iconMDI: 'mdi-finance',
        },
      ],
      features: [
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: 'mdi-store',
          titleFeatures: "Transaction Store",
          descriptionFeatures: "apply categorization /spend analytics and search against 6-12 months of data"
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: 'mdi-layers',
          titleFeatures: "Feature Store",
          descriptionFeatures: "to manage roll up variables around various budgeting controls and conditional alerts"
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: 'mdi-account-tie',
          titleFeatures: "Personalization /Customer 360",
          descriptionFeatures: "to arrive at customized investment advice and reporting/cross-sell for RMs"
        },
      ]
    };
  },
  mounted() {
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  }
};
</script>
<style scoped>
.heading-title1 {
  color: #dd8732 !important;
  line-height: 60px;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-title2 {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle2 {
  color: #dd8732 !important;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-caption {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
  font-size: 16px !important;
  line-height: 25px;
  font-weight: 500;
}

.heading-caption2 {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
  font-size: 16px !important;
  line-height: 25px;
  font-weight: 500;
}

.item-subtitle {
  color: #E18122 !important;
  font-family: 'Urbanist', sans-serif !important;
}

.rectangle {
  width: 200px;
  /* Width of the rectangle */
  height: 100px;
  /* Height of the rectangle */
  background-color: #f00;
  /* Color of the rectangle (replace #f00 with your desired color) */
  position: relative;
}

.triangle {
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  /* Height of the triangle (same as the rectangle) */
  border-bottom: 50px solid transparent;
  /* Height of the triangle (same as the rectangle) */
  border-right: 50px solid rgb(221, 218, 218);
  /* Width of the triangle and color (replace #f00 with your desired color, should match the rectangle color) */
  /* position: absolute; */
  /* left: -10px; */
  /* Move the triangle to the left to overlap with the rectangle */
  /* top: 0; */
  /* Align the triangle with the top of the rectangle */
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-foto1 {
  background-color: #152939e3;
  background-image: url(../assets/images/bg/bc-technology5.png);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>