<template>
<div class="container">
  <div class="section-title text-left mb--50 mb_sm--30 mb_md--30">
    <h2 class="heading-title">Contact Us.</h2>
    <p class="description" style="font-size: 24px !important;">
      Please choose the relevant area and follow the instructions to contact the correct team
    </p>
  </div>
  <v-row align="start" class="row--35">
    <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="1">
      <v-expansion-panels :value="opened" focusable class="panel">
        <v-expansion-panel >
          <v-expansion-panel-header class="panel-headers">Need help with our services or solutions?</v-expansion-panel-header>
          <v-expansion-panel-content class="py-5">
            <p class="description">
              Let us know, how we can help you achieve your goals.
            </p>
            <div class="form-wrapper">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form v-if="showForm" @submit.prevent="(e) => { handleSubmit(sendEmail(e)) }">
                  <ValidationProvider
                    name="name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label>
                      <input
                        placeholder="Your Name"
                        id="name"
                        type="text" 
                        v-model="formData.name"
                        required
                        name="name"
                      />
                      <span class="inpur-error">{{ errors[0] }}</span>
                    </label>
                  </ValidationProvider>

                  <ValidationProvider
                    name="email"
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <label>
                      <input
                        type="text"
                        id="email"
                        rules="required|email"
                        v-model="formData.email"
                        required
                        placeholder="Your email"
                      />
                      <span class="inpur-error">{{ errors[0] }}</span>
                    </label>
                  </ValidationProvider>

                  <ValidationProvider
                    name="message"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label>
                      <textarea
                        name="message"
                        id="message"
                        v-model="formData.message"
                        placeholder="Message"
                        required
                        style="height: 230px"
                      />
                      <span class="inpur-error">{{ errors[0] }}</span>
                    </label>
                  </ValidationProvider>

                  <v-btn
                    class="rn-button-style--2 btn_solid"
                    value="Send"
                    type="submit"
                    block
                    x-large
                  >
                    Submit
                  </v-btn>
                </form>
                <div v-else>
                  <v-snackbar :value="snackbar" @input="snackbar = false" timeout="3000">
                    Email sent successfully!
                  </v-snackbar>
                  <v-btn
                    class="rn-button-style--2 btn_solid"
                    value="Send"
                    type="submit"
                    block
                    x-large
                    @click="resetForm"
                  >
                    Send Another Email
                  </v-btn>
                </div>
              </ValidationObserver>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel >
          <v-expansion-panel-header class="panel-headers">Job seekers</v-expansion-panel-header>
          <v-expansion-panel-content class="py-5">
            <p class="description">
              Please visit our careers site to learn more about working at Sibernetik.
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel >
          <v-expansion-panel-header class="panel-headers">Other general requests</v-expansion-panel-header>
          <v-expansion-panel-content class="py-5">
            <p class="description">
              Please direct all queries to <a href="info@sibernetik.co.id" style="color: orange;">info@sibernetik.co.id</a> 
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      
    </v-col>
    <v-col lg="6" md="6" sm="12" cols="12" order="1" order-md="2">
      <div class="thumbnail mb_md--40 mb_sm--40">
        <!-- <img src="../../assets/images/about/about-6.jpg" /> -->
        <v-row>
          <v-col xl="12" lg="12" md="12">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3966.2953039713516!2d106.8107457!3d-6.2247395!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f17fb4982809%3A0x515c5ef1de334b0f!2sSibernetik%20Integra%20Data!5e0!3m2!1sen!2sid!4v1674467847152!5m2!1sen!2sid" width="520" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </v-col>
          <!-- <v-col xl="6" lg="6" md="6">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.2599381680357!2d106.79994061536989!3d-6.229421662742441!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f14595498c0b%3A0xd113b01fba9964b7!2sGraha%20Cevril!5e0!3m2!1sen!2sid!4v1675838102269!5m2!1sen!2sid" width="255" height="215" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6">
            <div class="footer-contactus mt-3">
              <ul class="ft-link">
                <li class="mt-5">
                  <div class="d-flex flex-row">
                    <v-btn
                      class="mx-2"
                      icon
                      dark
                      large
                      small
                      color="#dd8732"
                    >
                      <v-icon dark>
                        mdi-phone
                      </v-icon>
                    </v-btn>
                    <div class="d-flex flex-column">
                      <h6 class="mt-1">Phone:</h6>
                      <a href="" style="pointer-events: none;">(+62) 2129277994</a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </v-col>
          <v-col xl="6" lg="6" md="6">
            <div class="footer-contactus mt-3">
              <ul class="ft-link">
                <li class="mt-5">
                  <div class="d-flex flex-row">
                    <v-btn
                      class="mx-2"
                      icon
                      dark
                      large
                      small
                      color="#dd8732"
                    >
                      <v-icon dark>
                        mdi-email
                      </v-icon>
                    </v-btn>
                    <div class="d-flex flex-column">
                      <h6 class="mt-1">Email:</h6>
                      <a href="" style="pointer-events: none;">info@sibernetik.co.id</a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="12" lg="12" md="12">
            <div class="footer-contactus mt-3 d-flex flex-column">
              <ul class="ft-link">
                <div class="d-flex flex-row">
                  <v-btn
                    class="mx-2"
                    icon
                    dark
                    large
                    small
                    color="#dd8732"
                  >
                    <v-icon dark>
                      mdi-map-marker
                    </v-icon>
                  </v-btn>
                  <div class="d-flex flex-column">
                    <h6 class="mt-1">Our Office:</h6>
                    <p>Equity Tower, 35th Floor. Unit D, G, H, Jl. Jend. sudirman kav 52-53, RT.5/RW.3, SCBD, Kec. Kby. Baru, Jakarta, Daerah Khusus Ibukota Jakarta 12190</p>
                  </div>
                </div>
              </ul>
            </div>
          </v-col>
          <v-col xl="12" lg="12" md="12">
            <div class="footer-contactus mt-3">
              <ul class="ft-link">
                <ul class="ft-link">
                  <div class="d-flex flex-row">
                    <v-btn
                      class="mx-2"
                      icon
                      dark
                      large
                      small
                      color="#dd8732"
                    >
                      <v-icon dark>
                        mdi-map-marker
                      </v-icon>
                    </v-btn>
                    <div class="d-flex flex-column">
                      <h6 class="mt-1">Workshop:</h6>
                      <p>Graha Cevril, Jl.Senopati Raya No.6 A, RT.5/RW.1, Selong, Kec. Kby. Baru, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12110</p>
                    </div>
                  </div>
                </ul>
              </ul>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</div>
</template>

<script>
import emailjs from "emailjs-com";
  import { ValidationObserver } from "vee-validate";
  import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
  
  export default {
    name: 'ContactUs',
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    data() {
      return {
        opened: 0,
        formData: {
          name: "",
          email: "",
          message: "",
        },
        showForm: true,
        snackbar: false, // Add a data property for controlling the snackbar visibility
      };
    },
    methods: {
      sendEmail() {
        const serviceID = "service_7b4buyw"; // Ganti dengan Service ID dari EmailJS Anda
        const templateID = "template_lqkvcgm"; // Ganti dengan Template ID dari EmailJS Anda
        const userID = "ZfDbKAHiN84TbTZmp"; // Ganti dengan User ID dari EmailJS Anda

        emailjs
          .send(serviceID, templateID, this.formData, userID)
          .then((response) => {
            console.log("Email sent:", response);
            // Tambahkan logika atau tampilan untuk memberi tahu pengguna bahwa email telah terkirim.
            this.showForm = false;
            this.snackbar = true; // Show the snackbar after successful email submission
          })
          .catch((error) => {
            console.error("Failed to send email:", error);
            // Tambahkan logika atau tampilan untuk menangani kesalahan jika diperlukan.
          });
      },
      resetForm() {
        this.showForm = true;
        this.formData = {
          name: "",
          email: "",
          message: "",
        };
      },
    },
  };
</script>
<style scoped>
.container {
  display: block;
  margin:auto;
  text-align: left;
  border-radius: 10px;
  background-color: #ffff;
  padding: 50px;
}
.panel {
  font-family: 'Outfit', sans-serif !important;
}
.panel-headers {
  font-family: 'Outfit', sans-serif !important;
  font-size: 20px;
  font-weight: 500;
}
</style>